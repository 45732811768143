import { useState } from 'react';
import { OpenAIOutlined } from '@ant-design/icons';
import { Button, Popover, Input, Col, Row, Select } from 'antd';
import {
  actionPostComponentInsert,
  actionConfigComponentIndexActive,
  actionConfigActiveLayerEdit
} from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import './style.css';

const InlineChatForm = (props: any) => {
  const { component } = props;
  const [prompt, setPrompt] = useState('');
  const [messages, setMessages] = useState<any[]>([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const post = useSelector((store: any) => store.post);
  const sources = useSelector((store: any) => store.sources);

  const handleSubmit = () => {
    setLoading(true);
    setPrompt('');
    axios
      .post(`${process.env.REACT_APP_CHAT_API}chat/message`, {
        message: prompt,
        sources: post.sources,
        component: component,
        data: post.data[component.id]
      })
      .then((res) => {
        console.log(res.data);
        if (res.data?.components?.length > 0) {
          res.data.components.forEach((item: any, index: number) => {
            dispatch(
              actionPostComponentInsert({
                index: post.components.length,
                value: item
              })
            );
            if (item.type === 'map') {
              dispatch(
                actionConfigComponentIndexActive({
                  value: post.components.length
                })
              );
              dispatch(
                actionConfigActiveLayerEdit({
                  value: 0
                })
              );
            }
          });
        }
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <Row gutter={5}>
      <Col xs={24}>
        <Input.TextArea
          className="chat-ui-input"
          value={prompt}
          placeholder="How can I help?"
          onChange={(e) => setPrompt(e.target.value)}
        />
      </Col>
      <Col xs={17}>
        <Select disabled value={'component'} placeholder="Select context">
          <Select.Option value="component">Component</Select.Option>
        </Select>
      </Col>
      <Col xs={7}>
        <Button className="chat-ui-btn" onClick={handleSubmit} type="primary">
          Submit
        </Button>
      </Col>
    </Row>
  );
};

export const InlineChat = (props: any) => {
  const { component } = props;
  return (
    <div className="inline-chat-ui">
      <Popover content={<InlineChatForm component={component} />}>
        <Button size="small">
          <OpenAIOutlined />
        </Button>
      </Popover>
    </div>
  );
};
