import React from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message, Row } from 'antd';
import { Dictionary } from '@onaio/utils';
import { PostComponent } from '../PostComponent';
import { InlineChat } from '../Chat/components/InlineChat';
import { useResolvedStyles } from '../hooks';

import { drawerOpenSelector, activeComponentIndexSelector } from '../../../reducers/selectors/config';
import { getComponentById, userEditingPostSelector, getComponentSource } from '../../../reducers/selectors/post';

import {
  actionComponentMoveUp,
  actionComponentMoveDown,
  actionPostComponentDelete,
  actionConfigComponentIndexActive,
  actionConfigDrawerOpenEdit,
  actionPostKeyEdit,
  actionPostComponentCopy,
  actionPostComponentWidthEdit,
  actionPostComponentMove,
  actionPostComponentInsert
} from '../actions';
import { processTokens, replaceTokens } from '../helpers';
import { FilterControl } from './filter-control';
import { userEmailSelector } from '../../../reducers/selectors/user';
import { useEffect } from 'react';
import { ComponentExportMenu } from '../PostComponent/componentExportMenu';
import { GenericPostComponent } from '../../../configs/component-types';

/** selector factories */
const makeGetComponentById = () => getComponentById;
const makeGetComponentSource = () => getComponentSource;

/** interface for component props */
export interface GenericComponentProps {
  componentIndex: number; // index of the post component
  componentId: string; // id of the post component
  region?: number;
  layout?: string;
  dataRow?: Dictionary;
  isCard?: number;
  isChart?: boolean;
  isEmbed?: boolean;
  style?: Dictionary;
  fontFamily?: string;
  drawerTitle?: string; // title of the drawer
  drawerContent?: JSX.Element; // content of the drawer
  moveComponentUpActionCreator?: typeof actionComponentMoveUp; // redux action to move component up
  moveComponentDownActionCreator?: typeof actionComponentMoveDown; // redux action to move component down
  deleteComponentActionCreator?: typeof actionPostComponentDelete; // redux action to delete component
  editDrawerOpenActionCreator?: typeof actionConfigDrawerOpenEdit; // redux action to edit drawer config
  editPostKeyActionCreator?: typeof actionPostKeyEdit; // redux action to edit post key
  activeComponentActionCreator?: typeof actionConfigComponentIndexActive; // redux action to edit active component
  editWidthActionCreator?: typeof actionPostComponentWidthEdit; // redux action to edit width
  moveComponentActionCreator?: typeof actionPostComponentMove; // redux action to move component
  insertComponentActionCreator?: typeof actionPostComponentInsert;
  spaceComponent?: boolean;
}

/** default component props */
const defaultProps: Partial<GenericComponentProps> = {
  moveComponentUpActionCreator: actionComponentMoveUp,
  moveComponentDownActionCreator: actionComponentMoveDown,
  deleteComponentActionCreator: actionPostComponentDelete,
  editDrawerOpenActionCreator: actionConfigDrawerOpenEdit,
  editPostKeyActionCreator: actionPostKeyEdit,
  activeComponentActionCreator: actionConfigComponentIndexActive,
  editWidthActionCreator: actionPostComponentWidthEdit,
  moveComponentActionCreator: actionPostComponentMove,
  insertComponentActionCreator: actionPostComponentInsert
};

const GenericComponent: React.FC<GenericComponentProps> = (
  props: GenericComponentProps & { children?: React.ReactNode }
) => {
  const dispatch = useDispatch();

  // Memoize selectors
  const selectComponentById = useMemo(makeGetComponentById, []);
  const selectComponentSource = useMemo(makeGetComponentSource, []);

  // read edit status from redux
  const post = useSelector((store: Dictionary) => store.post);
  const space = useSelector((store: Dictionary) => store.space);
  const user = useSelector((store: Dictionary) => store.user);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [alertError, setAlertError] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [caption, setCaption] = useState<string>('');
  const styles = useResolvedStyles();

  const {
    componentIndex,
    componentId,
    isCard,
    isEmbed,
    region,
    children,
    dataRow,
    moveComponentDownActionCreator,
    moveComponentUpActionCreator,
    deleteComponentActionCreator,
    editDrawerOpenActionCreator,
    editPostKeyActionCreator,
    activeComponentActionCreator,
    editWidthActionCreator,
    moveComponentActionCreator,
    spaceComponent,
    style
  } = props;

  const drawerOpen = useSelector(drawerOpenSelector);
  /* @ts-ignore */
  const component = useSelector((state) => selectComponentById(state, { componentId }));
  const userEmail = useSelector(userEmailSelector);
  const userEditingPost = useSelector(userEditingPostSelector);
  /* @ts-ignore */
  const source = useSelector((state) => selectComponentSource(state, { componentId }));
  const activeComponentIndex = useSelector(activeComponentIndexSelector);

  useEffect(() => {
    if (component?.title || component?.title === '') {
      if (dataRow) {
        setTitle(processTokens(component.title, component, dataRow, source));
      } else {
        setTitle(replaceTokens(component, 'title'));
      }
    }
  }, [component, component?.title, dataRow, source]);

  useEffect(() => {
    if (isEditing && post.errors && Object.keys(post.errors).length && !alertError) {
      message.error('Oops, something went wrong, please check the error logs');
      setAlertError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, post.errors && Object.keys(post.errors).length);

  useEffect(() => {
    if (activeComponentIndex === componentIndex && drawerOpen === true) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentIndex, activeComponentIndex]);

  useEffect(() => {
    if (component?.caption || component?.caption === '') {
      if (dataRow) {
        setCaption(processTokens(component.caption, component, dataRow, source));
      } else {
        setCaption(replaceTokens(component, 'caption'));
      }
    }
  }, [component, component?.caption, dataRow, source]);

  if (!component) return null;

  let width = 'medium';
  if (component.type === 'title') {
    width = 'small';
  }

  const postComponentProps = {
    component: component,
    componentIndex: componentIndex,
    showEditMenu: !isEmbed && region === undefined && !isCard && userEmail === userEditingPost,
    showWidthMenu: region === undefined && !isCard && userEmail === userEditingPost,
    displayDrag: component?.type !== 'layout',
    displayMoveUp: component.region === undefined,
    displayMoveDown: component.region === undefined,
    displayInsert: component.layout ? false : true,
    onEdit: () => {
      if (isEditing) {
        setTimeout(() => {
          if (editPostKeyActionCreator) {
            dispatch(editPostKeyActionCreator());
          }
        }, 1000);
      } else {
        setIsEditing(true);
        if (editDrawerOpenActionCreator) {
          dispatch(
            editDrawerOpenActionCreator({
              value: true
            })
          );
        }
        if (activeComponentActionCreator) {
          dispatch(activeComponentActionCreator({ value: componentIndex }));
        }
        document.body.classList.add('drawer-open');
      }
    },
    onUp: () => {
      if (moveComponentUpActionCreator) {
        dispatch(moveComponentUpActionCreator({ componentIndex }));
      }
    },
    onDown: () => {
      if (moveComponentDownActionCreator) {
        dispatch(moveComponentDownActionCreator({ componentIndex }));
      }
    },
    onDelete: () => {
      if (deleteComponentActionCreator) {
        dispatch(deleteComponentActionCreator({ index: componentIndex }));
      }
    },
    onWidthChange: (value: string) => {
      if (editWidthActionCreator) {
        dispatch(
          editWidthActionCreator({
            componentIndex,
            value
          })
        );
      }
    },
    onDrag: () => {
      const body = document.body;
      if (body.classList.contains('move-component')) {
        body.classList.remove('move-component');
        document.querySelectorAll('.region-empty').forEach((item) => {
          item.removeEventListener('click', handleRegionClick, true);
        });
      } else {
        body.classList.add('move-component');
        document.querySelectorAll('.region-empty').forEach((item) => {
          item.addEventListener('click', handleRegionClick, true);
        });
      }
    }
  };

  const showComponentTitle = (title: string, isCard: number | undefined, component: Dictionary, post: Dictionary) => {
    if (title) {
      if (isCard) {
        return false;
      }
      if (component.type === 'button') {
        return false;
      }
      if (component?.type === 'layout' && post?.config?.type === 'map' && !component.region) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRegionClick = (e: any) => {
    const body = document.body;
    const layoutId = e.target.getAttribute('data-layoutId');
    const layoutComponent: GenericPostComponent | undefined = post.components.find(
      (component: GenericPostComponent) => component.id === layoutId
    );

    const regionIndex = e.target.getAttribute('data-regionIndex');
    if (moveComponentActionCreator) {
      dispatch(
        moveComponentActionCreator({
          index: componentIndex,
          layoutId: layoutId,
          region: regionIndex
        })
      );
    }
    setTimeout(() => {
      if (editPostKeyActionCreator) {
        dispatch(editPostKeyActionCreator());
      }
    }, 1000);
    body.classList.remove('move-component');
    document.querySelectorAll('.region-empty').forEach((item) => {
      (item as HTMLElement).removeEventListener('click', handleRegionClick, true);
    });
  };

  return (
    <div
      style={{
        marginRight: `${component?.marginRight}px`
      }}
    >
      <PostComponent {...postComponentProps}>
        <div
          data-testid="generic-component"
          id={`component-${component.id}`}
          className={`post--component-${spaceComponent ? 'navLayout' : component.type}
           post--component-size--${component.width || width}`}
        >
          {post.edit && component.type !== 'layout' && component.type !== 'title' && (
            <InlineChat component={component} />
          )}

          {post.config?.showInlineControls && component.type !== 'layout' && component.type !== 'title' && (
            <a
              href="#copy"
              className="inline-copy"
              onClick={() => {
                message.success('component copied');
                return dispatch(
                  actionPostComponentCopy({
                    componentIndex: componentIndex,
                    itemIndex: null,
                    value: Object.assign({}, post.components[componentIndex])
                  })
                );
              }}
            >
              Copy
            </a>
          )}
          <div className="component--header">
            {showComponentTitle(title, isCard, component, post) && (
              <h2
                style={{
                  fontFamily: styles.headingFontFamily,
                  color: styles.headingColor
                }}
                className="component--title"
              >
                {`${title}`}
              </h2>
            )}
            {component.filters && !isCard && (
              <div className="component--header-controls">
                <Row gutter={20}>
                  {component.filters.map(
                    (item: Dictionary, index: number) =>
                      item.expose === true && (
                        <FilterControl
                          key={index}
                          filterIndex={index}
                          componentIndex={componentIndex}
                          componentId={componentId}
                        />
                      )
                  )}
                </Row>
              </div>
            )}
          </div>
          <div className="component--content">
            {(user.uuid && component.dataExport) || (user.uuid && component.componentImageExport) ? (
              <ComponentExportMenu component={component} postId={post?.uuid} />
            ) : null}
            {children}
          </div>
          {caption && !isCard && isCard !== 0 && (
            <div className="component--footer">
              <p className="component--caption">{caption}</p>
            </div>
          )}
        </div>
      </PostComponent>
    </div>
  );
};

GenericComponent.defaultProps = defaultProps;

export { GenericComponent };
