import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { AkukoAPIService } from '../../../../../services/serviceClass';
import { IDENTITY_API } from '../../../../../configs/env';
import { POSTS_API } from '../../../../../configs/env';
import { actionConfigComponentIndexActive, actionPostPropertyEdit, actionConfigEdit } from '../../../actions';
import { actionUserGet, actionPostsGet } from '../../../../Account/actions';
import { message, Button, Tooltip, Dropdown } from 'antd';
import { ERROR_GENERIC } from '../../../../../configs/constants';
import { SaveOutlined, FormatPainterOutlined, CaretRightOutlined } from '@ant-design/icons';
import { UserDropdown } from '../../../../Account/UserDropdown';
import { resizeMaps } from '../../../Map/helpers';
import { GenericComponentProps } from '../../../Component';
import { PostMenu } from '../../../../Account/PostMenu';
import type { MenuProps } from 'antd';

export interface TopRegionProps {
  id: string;
}
const TopRegion: React.FC<TopRegionProps> = () => {
  const params = useParams<Dictionary>();
  const history = useHistory<Dictionary>();
  const store = useStore<Dictionary>();
  const dispatch = useDispatch();
  const config = useSelector((store: Dictionary) => store.config);
  const user = useSelector((store: Dictionary) => store.user);
  const posts = useSelector((store: Dictionary) => store.posts);
  const currentPost = useSelector((store: Dictionary) => store.post);
  const sources = useSelector((store: Dictionary) => store.sources);
  const sourceId = sources?.items[0]?.uuid;
  // const [disabled, setDisabled] = useState(true);
  const postId = config.activePostId;
  const [post, setPost] = useState<Dictionary>({
    uuid: posts?.[0]?.uuid,
    space_id: posts?.[0]?.space_id
  });

  const items: MenuProps['items'] = [
    {
      label: (
        <a
          href="#exit-post"
          onClick={(e) => {
            e.preventDefault();
            const currentState = store.getState();
            const postObj = Object.assign({}, currentState.post);
            const service = new AkukoAPIService(POSTS_API, `post/${postObj.uuid}`);
            const updatedPost = {
              ...postObj,
              components: postObj?.components?.filter((component: GenericComponentProps) => !component.spaceComponent)
            };
            service
              .update({
                ...updatedPost,
                // remove edit
                edit: null,
                data: {},
                sources: {}
              })
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              .then((res: any) => {
                setTimeout(() => {
                  history.push(`/post/${postObj.uuid}`);
                }, 500);
                dispatch(
                  actionPostPropertyEdit({
                    property: 'edit',
                    value: null
                  })
                );
                dispatch(
                  actionPostPropertyEdit({
                    property: 'updated',
                    value: res.updated
                  })
                );
                dispatch(
                  actionConfigComponentIndexActive({
                    value: 0
                  })
                );
              })
              .catch((err) => {
                message.error(err.message || ERROR_GENERIC);
              });
          }}
        >
          Save and exit
        </a>
      ),
      key: '1'
    }
  ];

  useEffect(() => {
    if (!posts.length && !postId) {
      if (user.email) {
        const service = new AkukoAPIService(IDENTITY_API, `user`);
        service
          .read(user.email)
          .then((res) => {
            actionUserGet(res);
            const service = new AkukoAPIService(POSTS_API, `post/account/${user.handle}`);
            return service.list();
          })
          .then((data) => {
            const posts = data as Dictionary[];
            setPost(posts[0]);
            actionPostsGet(data);
          })
          .catch((error) => {
            message.error(error.message || ERROR_GENERIC);
          })
          .finally(() => {
            // setDisabled(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const isActive = (path: string) => {
    if (location.pathname.includes(path)) {
      return 'active';
    }
  };

  /* @ts-ignore */
  const handleKeyPress = useCallback((e) => {
    if (e.shiftKey && e.metaKey && e.code === 'KeyP') {
      e.preventDefault();
      // preview shortcut (mac)
      document.body.classList.toggle('hide-settings');
      resizeMaps();
    }
    if (e.shiftKey && e.ctrlKey && e.code === 'KeyP') {
      // preview shortcut (windows)
      e.preventDefault();
      document.body.classList.toggle('hide-settings');
      resizeMaps();
    }
  }, []);

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress);
    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <div className="editor-top-region">
      <PostMenu />
      <Dropdown.Button
        type="primary"
        menu={{ items }}
        onClick={() => {
          const currentState = store.getState();
          const postObj = Object.assign({}, currentState.post);
          const service = new AkukoAPIService(POSTS_API, `post/${postObj.uuid}`);
          service
            .update(postObj)
            .then((res) => {
              const post = res as Dictionary;
              message.success('Post saved');
              dispatch(
                actionPostPropertyEdit({
                  property: 'updated',
                  value: post.updated
                })
              );
            })
            .catch((err) => {
              message.error(err.message || ERROR_GENERIC);
            });
        }}
      >
        <SaveOutlined /> Save
      </Dropdown.Button>

      <Tooltip title="Global styles">
        <Button
          className="btn-style-settings"
          onClick={() => {
            dispatch(actionConfigEdit({ property: 'activeComponentIndex', value: -1 }));
          }}
        >
          <FormatPainterOutlined />
        </Button>
      </Tooltip>
      <Tooltip title="Preview">
        <Button
          className="btn-toggle-settings"
          onClick={() => {
            document.body.classList.toggle('hide-settings');
            resizeMaps();
          }}
        >
          <CaretRightOutlined />
        </Button>
      </Tooltip>

      <UserDropdown />
    </div>
  );
};

export { TopRegion };
